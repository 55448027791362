<template>
  <div class="edit">
    <el-dialog
      title="操作"
      :visible.sync="isShow"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form ref="form" :model="form" label-width="80px" class="form" hide-required-asterisk>
        <el-form-item label="审核结果" prop="riskPass">
          <el-select v-model="form.riskPass" placeholder="选择">
            <el-option
              v-for="item in examOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="建议额度" prop="amount" v-show="passShow">
            <el-input type="number" v-model.number="form.amount" placeholder="请输入建议额度"></el-input>
        </el-form-item>
        <el-form-item label="方案(可选)" prop="productPlanId" v-show="passShow">
          <el-select v-model="form.productPlanId" placeholder="选择">
            <el-option
              v-for="item in productPlan"
              :key="item.id"
              :label="item.planName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指派复审" prop="financeId" v-show="passShow">
          <el-select v-model="form.financeId" placeholder="选择">
            <el-option
              v-for="item in finance"
              :key="item.id"
              :label="item.displayName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拒绝原因" prop="reason" v-show="rejShow">
            <el-input  type="text" v-model="form.reason" placeholder="请输入拒绝原因" maxlength="100"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
    finance: {
      type: Array,
      default: () => []
    },
    productPlan: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      form: {
        riskPass: ' ',
        amount: null,
        reason:'',
        financeId: null,
        productPlanId: null,
      },
      rules: {
        reason: [
          { required: true, message: '请输入拒绝原因', trigger: 'blur' }
        ]
      },
      examOpts: [
        { value: 0, label: '拒绝' },
        { value: 1, label: '通过' }
      ],
      width: '40%'
    };
  },
  computed: {
    passShow() {
      return this.form.riskPass === 1;
    },
    rejShow() {
      return this.form.riskPass === 0;
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.$refs['form'].resetFields();
    },
    submit() {
        let form = {
            amount: Math.floor(this.form.amount * 100),
            riskPass: this.form.riskPass,
            reason: this.form.reason,
            financeId: this.form.financeId,
            productPlanId: this.form.productPlanId
        }
        if (this.form.riskPass === ' ') {
          this.$message.error('请选择审核结果');
          return false
        }
        if (this.form.riskPass == '0' && !this.form.reason ) {
          this.$message.error('请填写拒绝原因');
          return false
        }else if(this.form.riskPass == '1' && parseFloat(this.form.amount)<0 ){
           this.$message.error('请输入大于0的建议额度');
          return false
        }
        this.$emit('submit', form);
        this.$refs['form'].resetFields();
    },
    openDialog() {
       if(document.documentElement.clientWidth < 480){
          this.width = '80%'
        }
        this.$nextTick(() => {
          // console.log(this.editData)
        this.form.amount = this.editData.virtualAmountCNY;
       
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit {
  .form {
    width: 70%;
    margin: 0 auto;
    text-align: left;
    .el-select {
      width: 100%;
    }
  }
}
</style>
