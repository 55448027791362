<template>
  <div class="user-box first-check">
    <search-form @search="searchList"></search-form>
    <first-check-table
      :list="list"
      :finance="finance"
      :productPlan="productPlan"
      @view="handleView"
      :currentPage="currentPage"
    ></first-check-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import searchForm from "../components/search-form";
import firstCheckTable from "./components/first-check-table";

import service from "./api";
import pagination from "@/mixins/pagination";

export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      finance: [],
      productPlan: [],
      page: {}
    };
  },
  components: { searchForm, firstCheckTable },
  methods: {
    getList(params) {
      // 切换分页当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      service.table({ page: this.currentPage, ...params }).then(res => {
        let { page = {}, list = [] } = res;

        this.page = page;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
      });
      service.getUserByJurisdiction({ jurisdictionId: 20 }).then(res => {
        this.finance = res;
      });
      service.getProductPlan({ pageSize: 999999 }).then(res => {
        this.productPlan = [{ id: null, planName: "不使用" }, ...res.records];
      });
    },
    searchList(data) {
      let params = { pageNum: 1, ...data };
      this.searchData = data;
      return this.getList(params);
    }
  },
  mounted() {
    this.getList({ pageNum: 1 });
  }
};
</script>

<style lang="scss" scoped></style>
